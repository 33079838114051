@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import 'icons';
@import 'mixins';

body {
    scroll-behavior: smooth;
}

[x-cloak] { display: none !important; }

.button {
    background-color: $color-primary;
    border-radius: $border-radius-2xl;
    color: white;
    border: none;
    outline: none;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    text-decoration: none;
    display: inline-block;

    &.button-inverted {
        background-color: white;
        color: $color-primary;

        &:hover {
            background-color: darken(white, 10%);
        }
    }

    &.button-outline {
        background-color: none;
        color: white;
        border: 2px solid white;
    }

    &.button-compact {
        font-size: $font-size-xs;
    }

    &.button-print {
        margin: 1rem auto;

        svg {
            max-height: 25px;
        }
    }

    &:hover {
        background-color: darken($color-primary, 10%);
    }
}

.form-actions {
    text-align: center;
    padding: $grid-gutter-width 0 $grid-gutter-width*2 0;
}

.form-actions-compact {
    text-align: center;
}

.main {
    @include media-breakpoint-down(lg) {
        display: grid;
    }
}

.main-content {
    min-height: 100%;
}


a {
    &:visited,
    &:link {
        color: $color-primary;
    }
}

header {
    background: url('../img/header.jpg') no-repeat center center;
    background-size: cover;

    .container {
        overflow: hidden;
        min-height: 300px;
        position: relative;
    }

    .logo {
        display: block;
        max-width: 250px;
        margin-top: -60px;
    }

    h1 {
        color: white;
        font-weight: $font-weight-bold;
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-down(lg) {
        .container {
            min-height: 200px;
        }

        .logo {
            margin: 70px auto -20px;
        }

        .title {
            display: none;
        }
    }
}

.sticky-region {
    position: sticky;
    top: 0;
    z-index: 998;
    margin: $grid-gutter-width 0 $grid-gutter-width*2 0;
    background-color: white;

    @include media-breakpoint-down(lg) {
        position: relative;
        margin-bottom: $grid-gutter-width;
        order: 2;
    }

    .page-status-wrapper {
        position: relative;
    }

    .page-status {
        background-color: $color-primary;
        border-radius: $border-radius-xl;
        padding: $spacer * 2;
        position: absolute;
        top: $spacer;
        left: $spacer;
        right: $spacer;
        color: white;
        text-align: center;
        margin-bottom: $grid-gutter-width;

        @include media-breakpoint-down(lg) {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            margin: $grid-gutter-width $spacer;
        }

        h3 {
            font-size: $font-size-sm;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: $grid-gutter-width;
        }

        .selected-line {
            font-size: $font-size-sm;
            font-weight: 500;
            margin-bottom: $spacer;

            span {
                text-align: center;
                display: block;
            }

            span.value {
                font-weight: $font-weight-bold;
            }
        }
    }

    .page-status-head {
        text-align: center;
        padding: calc($spacer * 2) 0;
    }

    .wizard-nav {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    ul.wizard {
        @include clear-list;
        display: flex;
        justify-content: space-between;

        > li {
            width: calc(25% - 10px);
            text-align: center;
            padding: $spacer 0;
            font-size: $font-size-sm;
            font-weight: bold;
            border-bottom: 1px solid $color-border-light;
            display: block;
            opacity: 0.3;

            &.completed {
                opacity: 1;
                color: $color-primary;
                cursor: pointer;
            }

            &.active {
                border-bottom-color: $color-primary;
                opacity: 1;
            }
        }
    }
}

.main-container {
    @include media-breakpoint-down(lg) {
        order: 1;
        margin-top: 2.5rem;
    }
}

.form-question {
    margin-bottom: $grid-gutter-width*2;

    h3 {
        font-size: $h5-font-size;
        font-weight: $font-weight-bold;
        margin-bottom: $grid-gutter-width;
    }

    h5 {
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
    }

    p {
        font-weight: $font-weight-light;
    }

    .divider-title {
        text-transform: uppercase;
        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
        position: relative;
        margin-bottom: $grid-gutter-width;

        span {
            position: relative;
            background-color: white;
            display: inline-block;
            padding-right: calc($spacer / 2);
            z-index: 50;
        }

        &::after {
            content: "";
            z-index: 1;
            display: block;
            background-color: $color-primary;
            height: 1px;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.alert-bubble,
.info-bubble {
    background-color: $color-primary;
    padding: $spacer;
    border-radius: $border-radius-xl;
    color: white;
    font-size: $font-size-sm;
    margin-top: $spacer;

    &.gray {
        background-color: #eee;
        color: inherit;
    }

    a:visited,
    a:link {
        color: white;
    }

    p {
        font-weight: 400;
    }
}

.alert-bubble {
    padding: $spacer $spacer $spacer $spacer*3.5;
    position: relative;

    p:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .icon {
        position: absolute;
        top: $spacer;
        left: $spacer;
        width: 30px;
        height: 30px;
    }
}

.modal {
    background-color: rgba($color-primary, .95);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    color: white;

    .inner {
        text-align: center;
        font-weight: 500;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        padding: $grid-gutter-width * 2;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: 100%;
            padding: $grid-gutter-width * 2;
        }
    }

    .close {
        width: 25px;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        color: white;
    }

}

.input-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $grid-gutter-width;

    .label {
        flex: 1;
        padding: $spacer;
        text-align: center;
        border-right: 1px solid $color-border-light;
        transition: $transition-base;
        cursor: pointer;

        &.active {
            color: $color-primary;
        }

        label {
            display: block;
            width: 100%;
        }

        input {
            accent-color: $color-primary;
        }

        span {
            display: block;
            font-size: $font-size-sm;
            // max-width: 80%;
            text-align: center;
            margin: 0 auto;
        }

        .radio-icon {
            text-align: center;
            margin: $spacer 0;
        }

        .icon {
            width: 20px;
            stroke: $color-primary;
        }

        button {
            background: none;
            padding: none;
            margin: 0;
            border: none;
            outline: none;
            transition: $transition-base;

            &:hover .icon {
                stroke: darken($color-primary, 20%);
            }
        }

        @include media-breakpoint-up(md) {
            &:last-child {
                border-right: none;
            }
        }

        @include media-breakpoint-down(md) {
            flex: 0 0 auto;
            width: calc(50% - 5px);
            margin-bottom: $spacer;
            border: 1px solid $color-border-light;
            border-radius: $border-radius-xl;

            &.active {
                border-color: $color-primary;
            }

            span {
                max-width: 100%;
            }
        }
    }
}

.verdict-content {
    @include media-breakpoint-down(lg) {
        padding-right: 0;
        border-right: 0;
    }

    .intro,
    .outro {
        background-color: lighten($color-border-light, 5%);
        border-radius: $border-radius-sm;
        padding: $spacer;
        margin-bottom: $grid-gutter-width;

        p:last-of-type  {
            margin-bottom: 0;
        }
    }
    .outro {
        margin-top: $grid-gutter-width;
    }

    h2 {
        font-weight: $font-weight-bold;
    }

    strong {
        font-weight: $font-weight-bold;
    }
}

.verdict-wrapper {
    order: 2;

    @include media-breakpoint-up(lg) {
        order: 1;
    }
}
.stats-wrapper {
    order: 1;

    @include media-breakpoint-up(lg) {
        order: 2;
    }
}

.stats-view {
    text-align: center;
    margin-bottom: $grid-gutter-width;
    padding-left: $grid-gutter-width;

    @include media-breakpoint-up(lg) {
        border-left: 1px solid $color-border-light;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: $grid-gutter-width*2;
        padding-bottom: $grid-gutter-width;
        border-bottom: 2px solid $color-primary;
    }

    .stats-title {
        font-size: $font-size-xs;
        font-weight: 500;
    }
    .primary {
        color: $color-hl1;
        font-size: 4rem;
        font-weight: 700;
        line-height: 4rem;
        margin-bottom: 1rem;
    }
    .secondary {
        color: $color-hl2;
        font-size: 3rem;
        font-weight: 700;
    }
    .tertiary {
        color: $color-hl3;
        font-size: 3rem;
        font-weight: 700;
    }
    .time {
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    .stats-issue {
        font-size: $font-size-xs;
        margin-bottom: calc($spacer * .75);
        color: $color-primary;
        text-align: center;
    }
}

.text-center {
    text-align: center;
}

p.top-text {
    margin-bottom: 3rem;
    font-weight: bold;
}

.lang-switch {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    background-color: $color-primary;
    border-bottom-left-radius: $border-radius-xl;
    border-bottom-right-radius: $border-radius-xl;
    padding: .5rem;
    margin-right: $grid-gutter-width;

    ul {
        padding-left: 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            display: inline-block;

            &:hover,
            &.active {
                font-weight: bold;
            }

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.intro {
    h3 {
        color: #f06400;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    h4 {
        border-left: 3px solid $color-primary;
        padding-left: .5rem;
        margin-top: 2rem;
    }
    button {
        margin-top: 1rem;
        margin-bottom: 3rem;
        text-transform: uppercase;
    }
}

.cable_types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid rgba(112, 112, 112, 0.1);

    .cable_type {
        flex: 1;
        padding: $spacer;
        text-align: center;
        border: 1px solid rgba(112, 112, 112, 0.1);

        .title {
            font-weight: bold;
        }
        .visual {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 100px;
            justify-content: center;
            display: flex;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
